.App {
    background-color: white;
}

.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

label {
    font-size: 16px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.input-style {
    font-size: 1.5rem;
}

.btn {
    font-size: 16px;
    padding: 5px 20px;
    border: 0;
    background-color: #3498db;
    color: #fff;
    border-radius: 3px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    width: 100%;
}

.btn:hover {
    background-color: #2386c8;
    width: 100%;
}

.btn:focus {
    outline: none;
    width: 100%;
}
