@import "~plaid-threads/scss/variables";
.grid {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 20rem;
    margin-right: 20rem;
    margin-bottom: 2 * $unit;
}

.title {
    margin-top: 60rem;
    margin-bottom: 2em;
    font-weight: 800;
    height: 6 * $unit;
}
/* FIX ME*/
.icon {
    top: -35px;
    position: relative;
    float: right;
}

.paddingText {
    padding-bottom: 3rem;
}

.dialogWidth {
    width: 1000rem;
}

.paddingTop {
    padding-top: 2rem;
}

.container {
    width: 55%;
    margin: auto 0;
  }
  
  .svgClass path{
    fill: green;
  }