@import "~plaid-threads/scss/variables";
.grid {
    // width: 80%;
    // display: flex;
    // flex-direction: column;
    // margin-left: 20rem;
    // margin-right: 20rem;
    // margin-bottom: 2 * $unit;
}

.title {
    margin-top: 9 * $unit;
    margin-bottom: 5em;
    font-weight: 800;
    height: 6 * $unit;
}
/* FIX ME*/
.icon {
    top: -35px;
    position: relative;
    float: right;
}


.paddingText {
    padding-bottom: 3rem;
}

.dialogWidth {
    width: 1000rem;
}

.paddingTop {
    padding-top: 10rem;
}


.container {
    // width: 80%;
    margin: auto 0;
  }
  
  .typography {
    margin-left: 10%;
  }