@import "~plaid-threads/scss/variables";
.grid {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 20rem;
    margin-right: 20rem;
    margin-bottom: 2 * $unit;
}

.title {
    margin-top: 9 * $unit;
    margin-bottom: 2em;
    font-weight: 800;
    height: 6 * $unit;
}
/* FIX ME*/
.icon {
    top: -35px;
    position: relative;
    float: right;
}

.container {
    padding-bottom: 2rem;
}

.hidden{
    display: none;
}

.MuiList-root > li {
    display: block;
}