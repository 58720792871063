$threads-font-path: "~plaid-threads/fonts";
@import "~plaid-threads/scss/typography";
@import "~plaid-threads/scss/variables";
@import "@fontsource/poppins"; 

* {
    box-sizing: border-box;
    font-family: "Poppins";
    font-size: 14px;
}

.MuiButtonBase-root .MuiButton-root {
    border-radius: 8px;
    background-color: red;
    border-style: dotted;
    font-size: 14px;
  }   

.mainDiv{
    margin: 0 auto;
    width: 80%;
}

span > svg {
    resize: horizontal; // you can safely omit this
    overflow: hidden;   // if you use resize, use this to fix weird scrollbar appearance
    width: 50px;
    height: auto;
}
