.swal2titleClass {
    font-size: 2.2rem;
}

.swal2labelClass {
    margin-left: 2rem;
    margin-right: 2rem;
}

.swal2inputClass {
    font-size: 1.5rem;
}

.swal2buttonClass {
    width: 15rem;
    padding: 1rem;
}

span button {
    margin-top: 1.5rem;
}

.helperText {
    font-size: 1.2rem;
}