@import "~plaid-threads/scss/variables";
.grid {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 20rem;
    margin-right: 20rem;
    margin-bottom: 2 * $unit;
}

.title {
    margin-top: 9 * $unit;
    margin-bottom: 5rem;
    font-weight: 800;
    height: 6 * $unit;
}

.paddingButton {
    margin-top: 2rem;
}

.helperText {
    font-size: 1.2rem;
}