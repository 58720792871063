@import "~plaid-threads/scss/variables";
.grid {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-bottom: 2 * $unit;
  font-size: 1rem;
}

.title {
  margin-top: 9 * $unit;
  margin-bottom: 0;
  font-weight: 800;
  height: 6 * $unit;
}

.paddingTop {
  padding-top: 2rem;
}

.paddingTop label {
  margin-top: -1.25rem;
  font-size: 1rem;
}

.paddingText {
  padding-top: 2rem;
}

.paddingText label {
  width: 50rem;
}

.paddingText label {
  font-size: large;
}

.fontsize {
  font-size: large;
}

.boxminWidth {
  min-width: 12rem;
}

.paddingTextCheckbox {
  font-weight: 400;
  font-size: 10px;
  padding-top: 1rem;
}
